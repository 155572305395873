.modal {
  @apply grid overflow-y-auto fixed inset-0 invisible justify-center place-items-center px-4 pt-4 pb-20 min-h-screen text-center bg-gray-500 bg-opacity-75 opacity-0 transition-opacity pointer-events-none;
  z-index: 999;
}
.modal-box {
  @apply inline-block overflow-y-auto py-8 px-10 m-auto text-left bg-white rounded-lg shadow-xl transition-all duration-200 ease-in-out transform;
  overscroll-behavior: contain;
}
.modal-open {
  @apply visible opacity-100 pointer-events-auto;
}
.modal-action {
  @apply flex justify-end mt-6 space-x-2;
}
