.btn {
  @apply inline-flex flex-wrap flex-shrink-0 justify-center items-center font-medium text-center rounded-md border shadow-sm transition duration-200 ease-in-out cursor-pointer select-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-none;
  @apply py-2 px-4 text-sm;

  /* default btn */
  & {
    @apply text-gray-700 bg-white border-gray-300 hover:bg-gray-100;
  }
  /* brand colors */
  &-primary {
    @apply text-white bg-blue-600 border-transparent hover:bg-blue-700;
  }
  /* disabled */
  &-disabled,
  &[disabled] {
    @apply pointer-events-none;
  }
  /* shapes */
  &-square {
    @apply h-10 w-10 p-0;
  }
  &-circle {
    @apply h-10 w-10 rounded-full p-0;
  }
  &-avatar {
    @apply border-0;
  }
  &-block {
    @apply w-full;
  }
}

[x-cloak],
#modal.htmx-request {
  @apply !hidden;
}
