form {
  @apply uk-form-stacked;
}

form > div {
  @apply sm:col-span-6;
}

form .errorlist {
  @apply text-sm text-red-600;
}

form > div:has(.errorlist) input,
select,
textarea {
  @apply uk-form-danger;
}

label {
  @apply text-gray-700 uk-form-label;
}

input:not([type="checkbox"], [type="radio"]) {
  @apply bg-white uk-input;
}

input[type="file"] {
  @apply file:hidden;
}

input[type="checkbox"] {
  @apply uk-checkbox;
}

input[type="radio"] {
  @apply uk-radio;
}

textarea {
  @apply bg-white uk-textarea;
}

select {
  @apply bg-white uk-select;
}

form > div:has(input[required], textarea[required], select[required]) label {
  @apply text-black;
}

form
  > div:has(input[required], textarea[required], select[required])
  label:before {
  content: "*";
}
